import TableBasic from "components/table/TableBasic";
import TableCellText from "components/table/TableTextCell";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { RiEdit2Line } from "react-icons/ri";
import { IoTrashBinOutline } from "react-icons/io5";
import ConfirmButton from "components/confirm";
import { useToast } from 'rc-toastr';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import Card from "components/card";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";

import { format } from "date-fns";

import { useBetween } from "use-between";
import _loading from "_loading";

import { AiFillExclamationCircle } from "react-icons/ai";
import Alert from "components/alert";

const Users = () => {

    const [data, setData] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [modalTitle, setModalTitle] = useState('Nuevo usuario')
    const [activeRow, setActiveRow] = useState({})
    const [error, setError] = useState('')
    const { toast } = useToast()

    const { setLoading } = useBetween(_loading);

    const urlModule = '/web/users/';

    const columns = [
        {
            Header: "Nombre",
            accessor: "nombre",
        },
        {
            Header: "Usuario",
            accessor: "login",
        },
        {
            Header: "Tipo",
            accessor: "tipo",
        },
        {
            Header: "Ultimo acceso",
            accessor: "ultimo_acceso",
        },
        {
            Header: "",
            accessor: "actions",
        },
    ];

    const handleEdit = (item) => {
        setActiveRow(item)
        setError('')
        setModalTitle(`Editar ${item.nombre}`)
        onOpen()
    }

    const loadData = async () => {

        const response = await axios.get(urlModule);
        if (response.data.success) {
            var data = response.data.data.map((item) => {
                return {
                    nombre: <TableCellText value={item.nombre} />,
                    login: <TableCellText value={item.login || item.email} />,
                    tipo: <TableCellText value={item.tipo} />,
                    ultimo_acceso: <TableCellText value={item.ultimo_acceso ? format(new Date(item.ultimo_acceso), 'dd/MM/yyyy HH:mm') : 'Nunca'} />,
                    actions: <p className="flex justify-end">
                        <button onClick={() => handleEdit(item)} className="mr-[10px] text-xs rounded-xl border-2 border-gray-300 px-2 py-2 text-base font-medium text-navy-700 transition duration-200 hover:bg-green-200 active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                            <RiEdit2Line />
                        </button>
                        <ConfirmButton message={`¿Seguro deseas eliminiar a ${item.nombre}`} onConfirm={() => { deleteData(item._id); }} className="text-xs rounded-xl border-2 border-red-300 px-2 py-2 text-base font-medium text-navy-700 transition duration-200 hover:bg-red-500 hover:text-white active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                            <IoTrashBinOutline />
                        </ConfirmButton>
                    </p>,
                    key: item._id
                }
            })
            setData(data)
        }

    };

    useEffect(() => {
        loadData();
    }, []);


    const deleteData = async (_id) => {

        setError('');
        setLoading(true);
        const response = await axios.delete(`${urlModule}/${_id}`);
        setLoading(false);
        if (response.data.success) {

            onClose();
            loadData();

        } else {

            toast.error(response.data.msj);

        }

    }


    const saveData = async () => {

        setError('');
        setLoading(true);
        const response = await axios.post(urlModule, activeRow);
        setLoading(false);
        if (response.data.success) {

            onClose();
            loadData();
            toast.success('Usuario actualizado');

        } else {

            setError(response.data.msj);

        }

    }

    return (
        <>
            <div>
                <div className="mt-5 h-full">
                    <TableBasic columnsData={columns} tableData={data} titulo="Listado" />
                </div>
            </div>

            <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[550px] !max-w-[85%] md:top-[20vh]">
                    <ModalBody>

                        <Card extra="rounded-[20px]">
                            <form onSubmit={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                saveData();
                            }}>
                                <div className="ml-[18px] flex h-[90px] w-[100%] flex-row items-center">
                                    <h2 className="text-2xl">{modalTitle}</h2>
                                </div>

                                <div className="rounded-lg grid grid-cols-2 gap-4 ml-5 mr-5 mb-5">
                                    <div>
                                        <InputField
                                            label="Nombre"
                                            id="nombre"
                                            type="text"
                                            defaultValue={activeRow.nombre}
                                            onChange={(v) => setActiveRow({ ...activeRow, nombre: v.target.value })}
                                        />
                                    </div>
                                    <div>
                                        <InputField
                                            label="Login / email"
                                            id="login"
                                            type="text"
                                            defaultValue={activeRow.login}
                                            onChange={(v) => setActiveRow({ ...activeRow, login: v.target.value })}
                                        />
                                    </div>
                                    <div>
                                        <SelectField label="Tipo" id="tipo" defaultValue={activeRow.tipo || 'client'} onChange={(v) => {
                                            setActiveRow({ ...activeRow, tipo: v.target.value })
                                        }} options={[
                                            {
                                                value: 'admin',
                                                label: 'Administrador'
                                            },
                                            {
                                                value: 'client',
                                                label: 'Usuario'
                                            }
                                        ]} />
                                    </div>
                                    <div>
                                        <InputField
                                            label="Contraseña"
                                            id="clave"
                                            type="text"
                                            placeholder={activeRow._id ? "Si deja en blanco no cambiará" : ""}
                                            onChange={(v) => setActiveRow({ ...activeRow, nclave: v.target.value })}
                                        />
                                    </div>
                                </div>

                                {error && <div className="m-5"><Alert
                                    icon={<AiFillExclamationCircle color="white" />}
                                    status="error"
                                    title="Error"
                                    message={error}
                                /></div>}

                                <div className="ml-[18px] mb-[20px] mr-[20px] mt-[40px] grid grid-cols-2">
                                    <div>
                                        <button type="button" onClick={onClose} className="rounded-lg border-2 border-red-50 p-2 font-medium text-red-500 transition duration-200 hover:bg-red-600 hover:text-white active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200">
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="text-end">
                                        <button type="button" onClick={saveData} className="rounded-lg bg-green-500 p-2 font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200">
                                            Guardar
                                        </button>
                                    </div>

                                </div>
                            </form>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default Users;