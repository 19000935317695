import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import Card from "components/card";

const ConfirmButtom = ({ onConfirm, message, className, children }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <button onClick={() => onOpen()} className={className}>
                {children}
            </button>
            <Modal isOpen={isOpen} className="!z-[1010]">
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[250px] !max-w-[700px] md:top-[15vh]">
                    <ModalBody>
                        <Card extra="rounded-[20px]">
                            <div className="ml-[18px] flex h-[90px] w-[100%] flex-row items-center">
                                <h2 className="text-2xl">Confirmación</h2>
                            </div>
                            <div className="rounded-lg ml-5 mr-5 mb-5 w-[400px] ">
                                <div>
                                    {message || '¿Estás seguro de realizar esta acción?'}
                                </div>
                            </div>
                            <div className="ml-[18px] mb-[20px] mr-[20px] grid grid-cols-2">
                                <div><button type="button" onClick={onClose} className="rounded-lg border-2 border-red-50 p-2 font-medium text-red-500 transition duration-200 hover:bg-red-600 hover:text-white active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200">
                                    Cancelar
                                </button></div>
                                <div className="text-end">
                                    <button type="button" onClick={() => { onConfirm(); onClose(); }} className="rounded-lg bg-green-500 p-2 font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200">
                                        Si, seguro
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ConfirmButtom;