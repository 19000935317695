import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import Users from "views/admin/users";
import Devices from "views/admin/devices";
import Groups from "views/admin/groups";
import Models from "views/admin/models";

// Icon Imports
import {
    MdHome,
    MdOutlineShoppingCart,
    MdGroupWork,
    MdAir,
} from "react-icons/md";

import { FcPortraitMode } from "react-icons/fc";
import { layout } from "@chakra-ui/system";
import { MdComputer } from "react-icons/md";
import { VscTerminal } from "react-icons/vsc";
import { SlOrganization } from "react-icons/sl";
import { SlUser } from "react-icons/sl";
import { IoHomeOutline } from "react-icons/io5";


const routes = [
    {
        name: "Home",
        layout: "/admin",
        path: "default",
        icon: <IoHomeOutline className="h-6 w-6" />,
        component: <MainDashboard />,
    },
    {
        name: "Usuarios",
        layout: "/admin",
        path: "users",
        icon: <SlUser className="h-6 w-6" />,
        component: <Users />,
        secondary: true,
    },
    {
        name: "Organizaciones",
        layout: "/admin",
        path: "organizations",
        icon: <SlOrganization className="h-6 w-6" />,
        component: <Groups />,
        secondary: true,
    },
    {
        name: "Dispositivos",
        layout: "/admin",
        path: "devices",
        icon: <MdComputer className="h-6 w-6" />,
        component: <Devices />,
        secondary: true,
    },
    {
        name: "Modelos",
        layout: "/admin",
        path: "models",
        icon: <VscTerminal className="h-6 w-6" />,
        component: <Models />,
        secondary: true,
    }
];
export default routes;
