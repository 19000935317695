import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import { useBetween } from 'use-between'
import _user from '_user'
import axios from 'axios'
import { AiFillExclamationCircle } from "react-icons/ai";
import Alert from "components/alert";
import { useGoogleLogin } from '@react-oauth/google';
import _loading from "_loading";

export default function Auth() {

    const navigate = useNavigate()
    const { setLoading } = useBetween(_loading);
    const { setUser } = useBetween(_user)
    const [error, setError] = useState('')

    async function loadUserData() {
        if (localStorage.getItem('token')) {
            setLoading(true)
            const response = await axios.get('/auth/user');
            if (response.data.success) {
                setUser(response.user)
                navigate(`/${response.data.user.tipo}`, { replace: true })
            }

        }
        setLoading(false)
    }

    async function login(form) {

        setLoading(true)
        const formData = new FormData(form)
        const data = Object.fromEntries(formData)
        const response = await axios.post('/auth/web', data)
        if (response.data.success) {
            setLoading(false)
            setUser(response.data.user)
            localStorage.setItem('token', response.data.token)
            if (response.data.user.tipo === 'admin')
                navigate('/admin', { replace: true })
            else
                navigate('/client', { replace: true })
        } else {

            setError(response.data.message)
            setLoading(false)
            setTimeout(() => {
                setError('')
            }, 5000);

        }

    }

    const googleLogin = useGoogleLogin({

        onSuccess: async tokenResponse => {

            setLoading(true)

            const response = await axios.post('/auth/googlesSignIn', tokenResponse)
            if (response.data.success) {
                setUser(response.data.user)
                localStorage.setItem('token', response.data.token)
                if (response.data.user.tipo === 'admin')
                    navigate('/admin', { replace: true })
                else
                    navigate('/client', { replace: true })
            } else {

                setError(response.data.message)
                setLoading(false)
                setTimeout(() => {
                    setError('')
                }, 5000);

            }

        },
    });


    const handleSubmit = (event) => {
        const form = event.currentTarget
        event.preventDefault()
        event.stopPropagation()
        login(form)
    }

    useEffect(() => {
        loadUserData()
    }, [])

    return (
        <form onSubmit={handleSubmit}>
            <div className="mt-1 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
                {/* Sign in section */}
                <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                    <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                        Bienvenido
                    </h4>
                    <p className="mb-9 ml-1 text-base text-gray-600">
                        Ingresa tus datos de acceso para continuar!
                    </p>
                    <button type="button" onClick={googleLogin} className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
                        <div className="rounded-full text-xl">
                            <FcGoogle />
                        </div>
                        <h5 className="text-sm font-medium text-navy-700 dark:text-white">
                            Acceder con Google
                        </h5>
                    </button>
                    <div className="mb-6 flex items-center  gap-3">
                        <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                        <p className="text-base text-gray-600 dark:text-white"> o </p>
                        <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                    </div>
                    <InputField
                        variant="auth"
                        extra="mb-3"
                        label="Usuario / Email"
                        id="email"
                        type="text"
                        name="username"

                    />
                    <InputField
                        variant="auth"
                        extra="mb-3"
                        label="Contraseña"
                        placeholder="Min. 8 characters"
                        id="password"
                        name="password"
                        type="password"
                    />
                    {
                        error ?
                            <Alert
                                icon={<AiFillExclamationCircle color="white" />}
                                status="error"
                                title="Error"
                                message={error}
                            />
                            : null}
                    <div className="mb-4 flex items-center justify-between px-2">
                        <Link
                            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                            to="/auth/restore"
                        >
                            Olvidaste tu contraseña?
                        </Link>
                    </div>
                    <button className="linear cursor-pointer mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                        Acceder
                    </button>
                    <div className="mt-4">
                        <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                            Aún no estás registrado?
                        </span>
                        <Link
                            to="/auth/register"
                            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                        >
                            Crear cuenta
                        </Link>
                    </div>
                </div>
            </div>
        </form>
    );
}
