import TableBasic from "components/table/TableBasic";
import TableCellText from "components/table/TableTextCell";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { RiEdit2Line } from "react-icons/ri";
import { IoTrashBinOutline } from "react-icons/io5";
import ConfirmButton from "components/confirm";
import { useToast } from 'rc-toastr';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import Card from "components/card";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";

import { format } from "date-fns";

import { useBetween } from "use-between";
import _loading from "_loading";

import { AiFillExclamationCircle } from "react-icons/ai";
import Alert from "components/alert";
import { act } from "react";

const Groups = () => {
    const [data, setData] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [modalTitle, setModalTitle] = useState('Nueva Organización');
    const [activeRow, setActiveRow] = useState({});
    const [error, setError] = useState('');
    const { toast } = useToast();

    const { setLoading } = useBetween(_loading);

    const columns = [
        {
            Header: "Nombre",
            accessor: "nombre",
        },
        {
            Header: "Descripción",
            accessor: "descripcion",
        },
        {
            Header: "Estado",
            accessor: "status",
        },
        {
            Header: "",
            accessor: "actions",
        },
    ];

    const handleEdit = (item) => {
        setActiveRow(item);
        setError('');
        setModalTitle(`Editar ${item.nombre}`);
        onOpen();
    };

    const handleAdd = () => {
        setActiveRow({
            config: {}
        });
        setError("");
        setModalTitle(`Nuevo Organización`);
        onOpen();
    };

    const loadData = async () => {
        const response = await axios.get(`/web/groups/`);
        if (response.data.success) {
            //console.log(response.data)
            const data = response.data.data.map((item) => ({
                nombre: <TableCellText value={item.nombre} />,
                descripcion: <TableCellText value={item.descripcion} />,
                ultimo_acceso: <TableCellText value={item.ultimo_acceso} />,
                actions: <p className="flex justify-end">
                    <button onClick={() => handleEdit(item)} className="mr-[10px] text-xs rounded-xl border-2 border-gray-300 px-2 py-2 text-base font-medium text-navy-700 transition duration-200 hover:bg-green-200 active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                        <RiEdit2Line />
                    </button>
                    <ConfirmButton message={`¿Seguro deseas eliminar a ${item.nombre}`} onConfirm={() => { deleteData(item._id); }} className="text-xs rounded-xl border-2 border-red-300 px-2 py-2 text-base font-medium text-navy-700 transition duration-200 hover:bg-red-500 hover:text-white active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                        <IoTrashBinOutline />
                    </ConfirmButton>
                </p>,
                key: item._id
            }));
            setData(data);
        }
        console.log('Loading data...');
    };

    useEffect(() => {
        loadData();
    }, [setLoading]);

    const deleteData = async (_id) => {
        setError('');
        setLoading(true);
        const response = await axios.delete(`/web/groups/${_id}`);
        setLoading(false);
        if (response.data.success) {
            onClose();
            loadData();
        } else {
            toast.error(response.data.msj);
        }
    }

    const saveData = async () => {
        setError('');
        setLoading(true);
        var response;
        if (activeRow._id) {
            response = await axios.post('/web/groups/', activeRow);
        } else {
            response = await axios.put('/web/groups/', activeRow);
        }
        setLoading(false);
        if (response.data.success) {
            onClose();
            loadData();
            toast.success('Organización actualizada');
        } else {
            setError(response.data.msj);
        }
    }

    return (
        <>
            <div className="mt-3 dark:text-white">
                <TableBasic columnsData={columns} tableData={data} titulo="Listado de Organizaciones"
                    addButton={() =>
                        handleAdd()} />
            </div>

            <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[550px] !max-w-[85%] md:top-[20vh]">
                    <ModalBody>
                        <Card extra="rounded-[20px]">
                            <form onSubmit={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                saveData();
                            }}>
                                <div className="ml-[18px] flex h-[90px] w-[100%] flex-row items-center">
                                    <h2 className="text-2xl">{modalTitle}</h2>
                                </div>

                                <div className="rounded-lg grid grid-cols-2 gap-4 ml-5 mr-5 mb-5">
                                    <div>
                                        <InputField
                                            label="Nombre"
                                            id="nombre"
                                            type="text"
                                            defaultValue={activeRow.nombre}
                                            onChange={(v) => setActiveRow({ ...activeRow, nombre: v.target.value })}
                                        />
                                    </div>
                                    <div>
                                        <InputField
                                            label="Descripción"
                                            id="descripcion"
                                            type="text"
                                            defaultValue={activeRow.descripcion}
                                            onChange={(v) => setActiveRow({ ...activeRow, descripcion: v.target.value })}
                                        />
                                    </div>
                                </div>

                                {error && <div className="m-5"><Alert
                                    icon={<AiFillExclamationCircle color="white" />}
                                    status="error"
                                    title="Error"
                                    message={error}
                                /></div>}

                                <div className="ml-[18px] mb-[20px] mr-[20px] mt-[40px] grid grid-cols-2">
                                    <div>
                                        <button type="button" onClick={onClose} className="rounded-lg border-2 border-red-50 p-2 font-medium text-red-500 transition duration-200 hover:bg-red-600 hover:text-white active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200">
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="text-end">
                                        <button type="submit" className="rounded-lg bg-green-500 p-2 font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default Groups;
