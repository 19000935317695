const SelectField = ({ label, options, extra, onChange, defaultValue, required, value, disabled }) => (
    <div className={extra}>
        {label && <label className={"text-sm text-navy-700 dark:text-white ml-3 font-bold"}>{label}</label>}
        <select disabled={disabled || false} onChange={onChange} defaultValue={defaultValue} value={value} required={required || false} className="mt-2 p-2.5 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white form-select appearance-none pr-8 pl-2 bg-no-repeat">
            <option value="">Seleccione</option>
            {options.map(option =>
                <option value={option.value} key={option.value}>{option.label}</option>
            )}
        </select>
    </div>
);

export default SelectField;