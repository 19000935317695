import React from "react";

import MainDashboard from "views/client/default";

import {
    MdHome
} from "react-icons/md";

const routes = [
    {
        name: "Home",
        layout: "/client",
        path: "default",
        icon: <MdHome className="h-6 w-6" />,
        component: <MainDashboard />,
    }
];
export default routes;
