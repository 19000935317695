import InputField from "components/fields/InputField";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import { useBetween } from 'use-between'
import _user from '_user'
import axios from 'axios'
import _loading from "_loading";
import Alert from "components/alert";
import { AiFillExclamationCircle } from "react-icons/ai";

const Restore = () => {
    const navigate = useNavigate()
    const { setLoading } = useBetween(_loading);
    const { setUser } = useBetween(_user)
    const [error, setError] = useState('')

    async function init() {
        if (localStorage.getItem('token')) {
            localStorage.removeItem('token')
            setUser({})
        }
        setLoading(false)
    }

    const register = async (form) => {

    }

    const handleSubmit = (event) => {
        const form = event.currentTarget
        event.preventDefault()
        event.stopPropagation()
        register(form)
    }

    useEffect(() => {
        init()
    }, [])


    return (
        <form onSubmit={handleSubmit}>
            <div className="mt-1 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
                {/* Sign in section */}
                <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                    <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                        Recuperación de cuenta
                    </h4>
                    <p className="mb-9 ml-1 text-base text-gray-600">
                        Si olvidaste tu contraseña, ingresa tu correo electrónico para restablecerla
                    </p>
                    <InputField
                        variant="auth"
                        extra="mb-3"
                        label="Usuario / Email"
                        id="email"
                        type="text"
                        name="username"

                    />
                    {
                        error ?
                            <Alert
                                icon={<AiFillExclamationCircle color="white" />}
                                status="error"
                                title="Error"
                                message={error}
                            />
                            : null}
                    <button className="linear cursor-pointer mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                        Validar usuario
                    </button>
                    <div className="mt-4">
                        <Link
                            to="/auth/login"
                            className="ml-1 text-sm font-medium text-grey-200 hover:text-grey-200 dark:text-white"
                        >
                            Volver al login
                        </Link>
                    </div>
                </div>
            </div>
        </form>
    );
}


export default Restore;