import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import axios, { AxiosError } from "axios";
import { ToastProvider } from 'rc-toastr'
import "rc-toastr/dist/index.css"
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    if (error instanceof AxiosError && error.response?.status === 401) {
      console.log('Unauthorized');
      localStorage.removeItem('token');
      window.location.reload();
    }
    return Promise.reject(error);
  },
);
axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  return Promise.reject(error);
});

root.render(
  <BrowserRouter>
    <ToastProvider config={{
      position: "top-right",
      duration: 3000,
      zIndex: 1000
    }} >
      <App /></ToastProvider>
  </BrowserRouter >
);
