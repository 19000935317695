import TableBasic from "components/table/TableBasic";
import TableCellText from "components/table/TableTextCell";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { RiEdit2Line } from "react-icons/ri";
import { IoTrashBinOutline } from "react-icons/io5";
import ConfirmButton from "components/confirm";
import { useToast } from "rc-toastr";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import Card from "components/card";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import SwitchField from "components/fields/SwitchField"
import { useBetween } from "use-between";
import _loading from "_loading";
import { AiFillExclamationCircle } from "react-icons/ai";
import Alert from "components/alert";
import NumberField from "components/fields/NumberField";

const Devices = () => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalTitle, setModalTitle] = useState("Nuevo Dispositivo");
  const [activeRow, setActiveRow] = useState({});
  const [error, setError] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [modelos, setModelos] = useState([]);
  const { toast } = useToast();
  const es = require("../../../components/i18n/es.json");

  const { setLoading } = useBetween(_loading);

  const columns = [
    {
      Header: "Serial",
      accessor: "serial",
    },
    {
      Header: "Nombre",
      accessor: "nombre",
    },
    {
      Header: "Organización",
      accessor: "grupo_nombre",
    },
    {
      Header: "Fecha de Creación",
      accessor: "fecha_creado",
    },
    {
      Header: "",
      accessor: "actions",
    },
  ];

  const handleEdit = (item) => {
    item.config ??= {};
    var data = JSON.parse(JSON.stringify(item));
    if (data.modelo) {
      data.modelo = modelos.find((m) => {
        return m.value === data.modelo
      });

      //console.log(data.config);

      // if (data.modelo.fields) {

      //   for (var i in data.modelo.fields) {
      //     var field = data.modelo.fields[i];
      //     if (field.tipo === 'switch' && data.config[field.id]) {
      //       var ndata = {
      //         checked: data.config[field.id],
      //         campos: field.campos
      //       }
      //       data.config[field.id] = ndata;
      //     }
      //   }

      // }

    }
    setActiveRow(data);

    setError("");
    setModalTitle(`Editar ${item.nombre}`);
    onOpen();

  }
  const handleAdd = () => {
    console.log(modelos);
    setActiveRow({
      config: {}
    });
    setError("");
    setModalTitle(`Nuevo dispositivo`);
    onOpen();
  };



  const loadData = async () => {
    setLoading(true);
    const response = await axios.get('/web/devices/');
    if (response.data.success) {
      const data = response.data.data.map((item) => ({
        serial: <TableCellText value={item.serial} />,
        nombre: <TableCellText value={item.nombre} />,
        grupo_nombre: <TableCellText value={item.grupo_nombre} />,
        fecha_creado: <TableCellText value={item.fecha_creado} />,
        actions: (
          <p className="flex justify-end">

            <button key={item._id}
              onClick={() => {
                handleEdit(item)
              }}
              className="mr-[10px] rounded-xl border-2 border-gray-300 px-2 py-2 text-base text-xs font-medium text-navy-700 transition duration-200 hover:bg-green-200 active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            >
              <RiEdit2Line />
            </button>
            <ConfirmButton
              message={`¿Seguro deseas eliminar a ${item.nombre}`}
              onConfirm={() => {
                deleteData(item._id);
              }}
              className="rounded-xl border-2 border-red-300 px-2 py-2 text-base text-xs font-medium text-navy-700 transition duration-200 hover:bg-red-500 hover:text-white active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
            >
              <IoTrashBinOutline />
            </ConfirmButton>
          </p>
        ),
        key: item._id,
      }));
      setData(data);
      setLoading(false);
    }
  };

  const loadOrganizations = async () => {
    const response = await axios.get("/web/groups");
    if (response.data.success) {
      let data = response.data.data.map((item) => ({
        value: item._id,
        label: item.nombre,
      }));
      setOrganizations(data);
    }
  };

  const loadModels = async () => {
    setLoading(true);
    const response = await axios.get("/web/models?short=true");
    if (response.data.success) {
      let data = response.data.data.map((item) => ({
        value: item._id,
        label: item.nombre,
        fields: item.campos_configuracion,
      }));
      setModelos(data);
    }
  };

  useEffect(() => {
    loadOrganizations();
    loadModels();

  }, []);

  useEffect(() => {
    loadData();
  }, [modelos]);


  const deleteData = async (_id) => {
    setError("");
    setLoading(true);
    const response = await axios.delete(`/web/devices/${_id}`);
    setLoading(false);
    if (response.data.success) {
      onClose();
      loadData();
    } else {
      toast.error(response.data.msj);
    }
  };

  const saveData = async () => {
    setError("");
    setLoading(true);
    var response;
    if (activeRow._id) {
      response = await axios.post("/web/devices", activeRow);
    } else {
      response = await axios.put("/web/devices", activeRow);
    }
    if (response.data.success) {
      onClose();
      loadData();
      toast.success(response.data.message);
    } else {
      setError(response.data.msj);
    }
    setLoading(false);
  };

  const drawFields = (fields) => {

    var widgets = [];
    widgets = fields.map((field, index) => {

      if (field.tipo === "texto") {
        //activeRow.config[field.id];
        return (
          <InputField
            key={field.id}
            label={field.nombre}
            id={field.id}
            name={field.id}
            value={activeRow.config[field.id]}
            defaultValue={activeRow.config[field.id] || field.por_defecto}
            required={field.requerido}
            extra="h-[90px]"
            onChange={(v) => {
              activeRow.config[field.id] = v.target.value;
              setActiveRow({ ...activeRow })
            }
            }
          />
        );
      }
      if (field.tipo === "numerico") {
        activeRow.config[field.id] ??= field.por_defecto;
        return (
          <NumberField
            key={index}
            label={field.nombre}
            id={field.id}
            value={activeRow.config[field.id] || field.por_defecto}
            extra="h-[90px]"
            required={field.requerido}
            onChange={(v) => {
              activeRow.config[field.id] = v.target.value;
              setActiveRow({ ...activeRow })
            }
            }
          />
        );
      }
      if (field.tipo === "selector") {

        if (typeof activeRow.config[field.id] === 'string') {
          activeRow.config[field.id] = field.opciones.find((opt) => opt.valor === activeRow.config[field.id]);
          setActiveRow({ ...activeRow });
        }

        return (
          <SelectField
            key={index}
            label={field.nombre}
            id={field.id}
            value={(activeRow.config[field.id] || {}).valor}
            extra="h-[90px]"
            required={field.requerido}
            options={field.opciones.map((opt) => ({
              value: opt.valor,
              label: opt.titulo,
            }))}
            onChange={(v) => {
              console.log('Change!!!!');
              activeRow.config[field.id] = field.opciones.find((opt) => opt.valor === v.target.value);
              setActiveRow({ ...activeRow })
            }}
          />
        );
      }
      if (field.tipo === 'switch') {

        if (typeof activeRow.config[field.id] === 'boolean' && activeRow.config[field.id]) {
          activeRow.config[field.id] = {
            checked: activeRow.config[field.id],
            campos: field.campos
          }
          setActiveRow({ ...activeRow });
        }

        return (
          <SwitchField
            key={index}
            label={field.nombre}
            id={field.id}
            defaultValue={field.por_defecto}
            value={(activeRow.config[field.id] || {}).checked || false}
            extra="h-[90px]"
            required={field.requerido}
            onChange={(v) => {
              activeRow.config[field.id] ??= {};
              if (v.target.checked) {
                activeRow.config[field.id].checked = true;
                activeRow.config[field.id].campos = field.campos;
              } else {
                activeRow.config[field.id].checked = false;
                if (activeRow.config[field.id].campos) {
                  activeRow.config[field.id].campos.forEach((campo) => {
                    activeRow.config[campo.id] = null;
                  });
                }
                activeRow.config[field.id].campos = null;
              }
              setActiveRow({ ...activeRow })
            }
            } />
        )
      }
    });
    return widgets;
  }

  return (
    <>
      <div className="mt-3 dark:text-white">
        <TableBasic
          columnsData={columns}
          tableData={data}
          titulo="Listado de Dispositivos"
          addButton={() =>
            handleAdd()}
        />
      </div>

      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[550px] !max-w-[85%] md:top-[20vh]">
          <ModalBody>
            <Card extra="rounded-[20px]">
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  saveData();
                }}
              >
                <div className="ml-[18px] flex h-[90px] w-[100%] flex-row items-center">
                  <h2 className="text-2xl">{modalTitle}</h2>
                </div>

                <div className="mb-5 ml-5 mr-5 grid grid-cols-2 gap-4 rounded-lg">
                  <div>
                    <InputField
                      label="Serial"
                      id="serial"
                      name="serial"
                      type="text"
                      required={true}
                      defaultValue={activeRow.serial}
                      onChange={(v) =>
                        setActiveRow({ ...activeRow, serial: v.target.value })
                      }
                    />
                  </div>
                  <div>
                    <InputField
                      label="Nombre"
                      id="nombre"
                      name="nombre"
                      type="text"
                      defaultValue={activeRow.nombre}
                      onChange={(v) =>
                        setActiveRow({ ...activeRow, nombre: v.target.value })
                      }
                    />
                  </div>
                  <div>
                    <SelectField
                      label="Modelo"
                      id="modelo"
                      options={modelos}
                      disabled={activeRow._id && activeRow.modelo}
                      value={(activeRow.modelo || {}).value}
                      onChange={(v) => {
                        setActiveRow({ ...activeRow, modelo: modelos.find((m) => m.value === v.target.value) })
                      }
                      }
                    />
                  </div>
                  <div>
                    <SelectField
                      label="Organización"
                      id="grupo"
                      options={organizations}
                      value={activeRow.grupo}
                      onChange={(v) =>
                        setActiveRow({ ...activeRow, grupo: v.target.value })
                      }
                    />
                  </div>

                </div>
                <hr className="mb-5" />
                <div className="mb-5 ml-5 mr-5 grid grid-cols-2 gap-4 rounded-lg">
                  {
                    activeRow.modelo && activeRow.modelo.fields && drawFields(activeRow.modelo.fields)
                  }
                  {
                    activeRow.config && Object.keys(activeRow.config).map((key) => {
                      if ((activeRow.config[key] || {}).campos) {
                        return drawFields(activeRow.config[key].campos);
                      }
                    })
                  }
                </div>

                {error && (
                  <div className="m-5">
                    <Alert
                      icon={<AiFillExclamationCircle color="white" />}
                      status="error"
                      title="Error"
                      message={error}
                    />
                  </div>
                )}

                <div className="mb-[20px] ml-[18px] mr-[20px] mt-[40px] grid grid-cols-2">
                  <div>
                    <button
                      type="button"
                      onClick={onClose}
                      className="rounded-lg border-2 border-red-50 p-2 font-medium text-red-500 transition duration-200 hover:bg-red-600 hover:text-white active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="rounded-lg bg-green-500 p-2 font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200"
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Devices;
