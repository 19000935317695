import Devices from "../devices";

const MainDashboard = () => {
    return (
        <div className="mt-3">
            <h1 className="dark:text-white">Dashboard</h1>
            <Devices />
        </div>
    );
}

export default MainDashboard;