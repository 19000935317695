import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useBetween } from 'use-between';
import _user from '_user';
import axios from 'axios';


const PrivateRoute = (props) => {
    const { type } = props;
    const auth = localStorage.getItem('token');
    const navigate = useNavigate();
    const { setUser } = useBetween(_user);
    const loadUserData = async () => {
        const response = await axios.get('/auth/user');
        if (response.data.success) {
            if (response.data.user.tipo !== type) {
                localStorage.removeItem('token')
                navigate('/auth/login', { replace: true })
            } else {
                setUser(response.data.user)
            }
        } else {
            localStorage.removeItem('token')
            navigate('/auth/login', { replace: true })
        }
    }

    useEffect(() => {
        loadUserData()
    }, [])

    return auth ? <Outlet /> : <Navigate to="auth/login" />;
}

export default PrivateRoute;