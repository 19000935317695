/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import logo from "assets/img/logo.png";
import routesAdmin from "routes/admin";
import routesClient from "routes/client";
import { useBetween } from "use-between";
import _user from "_user";
import { useEffect, useState } from "react";

const Sidebar = ({ open, onClose }) => {

  const { user, setUser } = useBetween(_user)
  const [routes, setRoutes] = useState([])

  useEffect(() => {
    if (!user) return
    if (user.tipo === 'admin') {
      setRoutes(routesAdmin)
    } else {
      setRoutes(routesClient)
    }
  }, [user]);

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${open ? "translate-x-0" : "-translate-x-96"
        }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[30px] mt-[30px] flex items-center`}>
        <div className="mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white" >
          <img src={logo} alt="logo" className="logo" />
        </div>
      </div>
      <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes} />
      </ul>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
