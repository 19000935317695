const Alert = (props) => {
    const { icon, title, message, status } =
        props;
    return (
        <div className="flex w-full flex-col gap-2"><div className={`flex justify-between h-[48px] w-full items-center py-[6px] pl-[17px] pr-[6px] ${status === 'error' ? 'bg-red-500 dark:!bg-red-400' : status === 'success' ? 'bg-green-500 dark:!bg-green-400' : 'bg-blue-500 dark:!bg-blue-400'} rounded-lg`}>
            <div className="flex h-full w-full items-center gap-2 justify-start">
                {icon}
                <h5 className="text-base text-white"><span className="pr-[5px] font-bold">{title}</span> {message}</h5>
            </div>
        </div>
        </div >
    );
}

export default Alert;