import TableBasic from "components/table/TableBasic";
import TableCellText from "components/table/TableTextCell";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { RiEdit2Line } from "react-icons/ri";
import { IoTrashBinOutline } from "react-icons/io5";
import ConfirmButton from "components/confirm";
import { useToast } from 'rc-toastr';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import Card from "components/card";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";

import { format } from "date-fns";

import { useBetween } from "use-between";
import _loading from "_loading";

import { AiFillExclamationCircle } from "react-icons/ai";
import Alert from "components/alert";
import { act } from "react";

const Models = () => {
    const [data, setData] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [modalTitle, setModalTitle] = useState('Modelos');
    const [activeRow, setActiveRow] = useState({});
    const [error, setError] = useState('');
    const { toast } = useToast();

    const { setLoading } = useBetween(_loading);
    // const downloadFile = () => {
    //     const { modelsFile } = this.state;
    //     const fileName = "modelos";
    //     const json = JSON.stringify(modelsFile, null, 2);
    //     const blob = new Blob([json], { type: "application/json" });
    //     const href = URL.createObjectURL(blob);

    //     // create "a" HTLM element with href to file
    //     const link = document.createElement("a");
    //     link.href = href;
    //     link.download = fileName + ".json";
    //     document.body.appendChild(link);
    //     link.click();

    //     // clean up "a" element & remove ObjectURL
    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(href);
    // }

    const columns = [
        {
            Header: "ID",
            accessor: "uid",
        },
        {
            Header: "Nombre",
            accessor: "nombre",
        },
        {
            Header: "Entradas",
            accessor: "cant_entradas",
        },
        {
            Header: "Dispositivos",
            accessor: "cant_dispositivos",
        },
    ];

    // const JSONToFile = (obj, modelo) => {
    //     const blob = new Blob([JSON.stringify(obj, null, 2)], {
    //       type: 'application/json',
    //     });
    //     const url = URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = `${modelo}.json`;
    //     a.click();
    //     URL.revokeObjectURL(url);
    //   };

    //   JSONToFile({ test: 'se ha descargado el archivo' }, 'modelos');

    const loadData = async () => {
        const response = await axios.get(`/web/models/`);
        if (response.data.success) {
            const data = response.data.data.map((item) => ({
                uid: <TableCellText value={item.uid} />,
                nombre: <TableCellText value={item.nombre} />,
                cant_entradas: <TableCellText value={item.cant_entradas} />,
                cant_dispositivos: <TableCellText value={item.cant_dispositivos} />,
                key: item._id
            }));
            setData(data);
        }
        console.log('Loading data...');
    };

    useEffect(() => {
        loadData();
    }, [setLoading]);

    const deleteData = async (_id) => {
        setError('');
        setLoading(true);
        const response = await axios.delete(`/web/models/${_id}`);
        setLoading(false);
        if (response.data.success) {
            onClose();
            loadData();
        } else {
            toast.error(response.data.msj);
        }
    }

    return (
        <>
            <div className="mt-3 dark:text-white">
                <TableBasic columnsData={columns} tableData={data} titulo="Modelos"
                    downloadButton={() => { }}
                    uploadButton={() => { }}
                />
            </div>
        </>
    );
};

export default Models;